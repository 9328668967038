import React from 'react';
import { Container, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
// import Lightbox from 'fslightbox-react';
import TopDark from '../../../components/Modules/Top/TopDarkRelative'
import Footer from '../../../components/Modules/FooterPage'

const bacteria = (
    <Popover id="popover-basic">
        <Popover.Title as="h3" style={{maxWidth: '800px'}}>Рассмотрим основных вредителей, чтобы понять, как не допустить их размножения и обсеменения продуктов:</Popover.Title>
        <Popover.Content>
        <div className="smallcard-popup-wrapper">
            <div className="smallcard-img">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/bacteria/gnil.jpg" alt=""/>
            </div>
            <div className="smallcard-body">
                <p><strong>Гнилостные бактерии</strong> – в первую очередь именно они ухудшают качество продукта в период хранения. 
                Наиболее распространённая в этом семействе – протейная палочка, которая может выживать при +65°С в течение 30минут. 
                Эти бактерии устойчивы к сухому и солёному продукту, размножаются при температуре +6…+43°С, а оптимальная для них среда это +20..+37°С.</p>
            </div>
        </div>
        <div className="smallcard-popup-wrapper">
            <div className="smallcard-img">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/bacteria/kishechnaya.jpg" alt=""/>
            </div>
            <div className="smallcard-body">
                <p><strong>Кишечная палочка</strong> имеет оптимальную температуру развития +37°С, погибает при +60°С за 15 минут. 
                Любимое место обитания готовые блюда и наш кишечник. Здесь важно понимать, что у кишечной палочки 100 видов и большинство из них 
                приводят к отравлению человека.</p>
            </div>
        </div>
        <div className="smallcard-popup-wrapper">
            <div className="smallcard-img">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/bacteria/perfingens.jpg" alt=""/>
            </div>
            <div className="smallcard-body">
                <p><strong>Палочка перфингенс </strong>имеет высокую оптимальную температуру развития +37..+45°С, устойчива к нагреву, 
                размножается в продуктах при вторичном нагреве и при температуре выше +15°С, не любит кислую среду.</p>
            </div>
        </div>
        <div className="smallcard-popup-wrapper">
            <div className="smallcard-img">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/bacteria/salmonella.jpg" alt=""/>
            </div>
            <div className="smallcard-body">
                <p><strong>Сальмонеллы</strong> – очень опасны. Оптимальная температура для их развития +37°С, выдерживают 3 часа при температуре +60°С, 
                10 минут при температуре +75°С, но при +100°С мгновенно погибают. Не любят кислую среду и солёную. Не меняют вкус, вид и запах продуктов, 
                от этого становятся ещё опаснее.</p>
            </div>
        </div>
        <div className="smallcard-popup-wrapper">
            <div className="smallcard-img">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/bacteria/golden.jpg" alt=""/>
            </div>
            <div className="smallcard-body">
                <p><strong>Золотистый стафилококк</strong> – постоянно находится рядом с нами: в воздухе, на нашей коже, полости рта и носа. 
                Пищеварительная система человека является кислой средой, где бактерии не развиваются. Опасен не сам стафилококк, а токсины, 
                которые он выделяет, попадая и размножаясь на продуктах, таких как мясо, рыба, овощи, кондитерские изделия и пр. 
                При этом вкус, запах и консистенция продуктов не меняются. Оптимальная температура развития +30..+37°С. 
                Стафилоккок остаётся жизнеспособен при снижении температуры до +15°С, при заморозке и высушивании может сохраняться длительное время. 
                Погибает при +80°С за 30минут, но его остаточные токсины являются ядом, который можно нейтрализовать при +100°С в течение 1,5..2часов.</p>
            </div>
        </div>
        <div className="smallcard-popup-wrapper">
            <div className="smallcard-img">
                <img src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/bacteria/botulinus.jpg" alt=""/>
            </div>
            <div className="smallcard-body">
                <p><strong>Ботулинус</strong> – бактерия, которая может развиваться без доступа воздуха и вызывает наиболее опасное пищевое отравления - ботулизм. 
                В процессе жизнедеятельности ботулинус выделяет ядовитые споры, которые не разрушаются при солении, мариновании и замораживании. 
                Токсин ботулиновой палочки чрезвычайно ядовит. Высокая температура – главный способ борьбы с токсином. 
                Температура +80°С разрушит бактерии за 30минут, при +100°С за 15 минут, варка больших кусков мяса и рыбы – час.</p>
            </div>
        </div>
        </Popover.Content>
    </Popover>
);

const CookNChill = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>

            {/* PAGE CONTENT  */}
                <div className="refeng-page-container">
                        <div className="postcontent">
                            <h1>Заморозка и охлаждение продуктов по технологии "Cook and Chill" и "Cook and Freeze"</h1>
                            <br/><br/>

                            
                                    <p>Основные предприятия, применяющие данную технологию и последующую реализацию до конечного потребителя – это кейтеринг, 
                                        ресторанный бизнес, авиа и ж/д перевозки, в меньшей степени фуд-ритейл.</p>

                                        <figure>
                                            <img
                                            className="postcontent-image-sixty" 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents/zamorozka/cook-n-chill/scheme.jpg" 
                                            alt="Принцип технологии Кук энд Чил" />
                                            <figcaption style={{textAlign: "center"}}><em>Принцип технологии "Cook & Chill"</em></figcaption>
                                        </figure>

                                    <p>Данная технология появилась в Европе и других развитых странах в конце прошлого столетия и стала активно развиваться в России 
                                        в начале нулевых. Дословный перевод: готовить и охлаждать. Суть технологии Cook and Chill – приготовление продукта в горячем цеху, 
                                        упаковка и быстрое охлаждение до оптимальной температуры хранения (как правило до +4°С и перемещение в камеру хранения с температурным 
                                        режимом 0..+2°С).</p>
                                    <p>Существует также технология Cook and Freeze, которая отличается от Cook and Chill тем, что продукт не охлаждается, 
                                        а замораживается до температуры в центре -12..-18°С, далее хранится в камерах с температурным режимом -18..-25°С. 
                                        Данная технология применяется в более промышленных масштабах в основном для реализации в сегменте фуд-ритейл.</p>

                                        <figure>
                                            <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\cookchill-line.jpg" 
                                            alt="Линия охлаждения и заморозки продуктов" />
                                            <figcaption style={{textAlign: "center"}}><em></em></figcaption>
                                        </figure>

                                    <p>Важны все этапы производства, хранения и перемещения готовых блюд:</p>
                                        <ul class="post-ul-checked">
                                            <li class="post-li-checked">хранение сырья</li>
                                            <li class="post-li-checked">первичной обработки (гигиена, время и температура в производственных цехах)</li>
                                            <li class="post-li-checked">термическая обработка (температура и время)</li>
                                            <li class="post-li-checked">упаковка (гигиена и время)</li>
                                            <li class="post-li-checked">охлаждение (время и конечная температура)</li>
                                            <li class="post-li-checked">хранение готового продукта до его употребления (включая перемещение к потребителю).</li>
                                        </ul>

                                    <p>На каждом из этапов возможно появление вредных 
                                        <OverlayTrigger trigger="click" placement="bottom" overlay={bacteria}>
                                            <em style={{fontWeight: '700', cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}> бактерий, </em>
                                        </OverlayTrigger>
                                        их размножение и выделение токсинов. 
                                        В определённых условиях, благоприятных для роста бактерий, они удваивают своё число каждые 20 минут, 
                                        что позволяет одной бактерии превратиться в миллион уже через 6 часов. Оптимальная температура хранения 0..+2°С 
                                        замедляет и приостанавливает рост большинства бактерий, но не убивает их. А токсины, которые являются продуктом 
                                        их жизнедеятельности, сохраняются даже в замороженных и высушенных продуктах и могут быть нейтрализованы 
                                        только при длительной термообработке при высоких температурах, которая невозможна для многих готовых блюд. 
                                        Поэтому главная задача – не допустить роста бактерий и не создавать для них благоприятные условия роста и размножения, 
                                        такие как: тепло, влажность и питательная среда.</p>

                                        <Container fluid>
                                            <Row>
                                                <Col xl={6}>
                                                    <figure>
                                                        <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                                        src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\cookchill-hall-1.jpg" 
                                                        alt="Промышленное охлаждение продуктов" />
                                                        <figcaption style={{textAlign: "center"}}><em></em></figcaption>
                                                    </figure>
                                                </Col>
                                                <Col xl={6}>
                                                    <figure>
                                                        <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                                        src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\cookchill-hall-2.jpg" 
                                                        alt="Промышленная заморозка продуктов питания" />
                                                        <figcaption style={{textAlign: "center"}}><em></em></figcaption>
                                                    </figure>
                                                </Col>
                                            </Row>
                                        </Container>

                                    <p>Бактерии могут расти и размножаться при температуре +6..+45°С, а наиболее оптимальная температура роста для большинства 
                                        бактерий это +25..+40°С. Чтобы минимизировать попадание бактерий на продукты необходимо соблюдать гигиену. Не допустить 
                                        рост бактерий возможно только обеспечив температурную цепочку охлаждения всех помещений: от момента приёмки сырья, 
                                        до момента термической обработки (приготовления), далее не допуская простоя после горячего цеха быстрое охлаждение 
                                        продукта до +4..+6°С и его перемещения в камеры хранения готовой продукции с температурным режимом 0..+2°С. Последующую 
                                        температурную цепочку до потребителя необходимо также контролировать на столько, на сколько это возможно.</p>

                                    <p>В процессе приготовления продукта (термическая обработка) большая часть бактерий погибает от высокой температуры, 
                                        которую желательно довести до +100°С. Но некоторые продукты согласно технологической карты нельзя долго обрабатывать 
                                        при высоких температурах из-за того, что они теряют свои потребительские качества (влагосодержание, вкус, цвет, 
                                        запах, органолептические и реологические свойства). Для такой более деликатной продукции необходим особый контроль 
                                        качества сырья на этапе приёмки, неразрывная температурная цепочка, минимальное время нахождения сырья и готового 
                                        продукта в производственных помещениях и строжайшее выполнение гигиены на всех этапах.</p>

                                        <figure>
                                            <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\Cook-Chill-a.jpg" 
                                            alt="Гигиена при термической обработке с последующим охлаждением продуктов" />
                                            <figcaption style={{textAlign: "center"}}><em>В процессе обработки продуктов для охлаждения, гигиена помещения превыше всего.</em></figcaption>
                                        </figure>

                                    <p>Продукт, имеющий длительную термообработку при +100°С и возможность последующей герметичной упаковки в горячем 
                                        виде перед охлаждением, в последующем может храниться в камерах готовой продукции достаточно долго (3 месяца или даже более). 
                                        Но таких продуктов, которые возможно герметично упаковать в горячем виде до их охлаждения не так много. 
                                        В основном это жидкие продукты, такие как супы и соусы, которые в горячем состоянии перекачиваются специальными 
                                        насосами в небольшую упаковку для розничного потребления или в большую для сегмента B2B*.</p>

                                    <p>При небольшой производительности и при определённой консистенции продукта его разливают вручную в пластиковые герметичные ёмкости. 
                                        Большая упаковка для жидкого продукта – это как правило пластиковые пакеты с герметичной клипсацией или запайкой. 
                                        Герметичная упаковка позволяет охлаждать продукт в ледяной воде, т.к. это наиболее быстрый способ охлаждения 
                                        (теплоёмкость и теплоотдача воды существенно превосходит воздух). При охлаждении ледяной водой продукт раскладывают 
                                        в специальные кассеты и погружают в ванные, где помимо циркуляции самой воды процесс теплообмена усиливают воздушным барботажем. 
                                        Иногда ледяную воду получают за счёт добавления льда и его плавления (теплота фазового перехода льда 330кДж/кг против 
                                        теплоёмкости воды, которая на 1С нагрева или охлаждения имеет 4,18кДж/(кг*К). В некоторых ситуациях охлаждение водой 
                                        технически невозможно или нерационально: для небольших производительностей менее 500кг в час такая система будет стоить 
                                        не дёшево на единицу продукции или могут быть сложности с маркировкой упаковки или наклейками до упаковки или после охлаждения, 
                                        когда упаковка мокрая. В таких ситуациях применяется более традиционная технология шокового охлаждения на тележках в 
                                        специальных камерах или спиральные охладители при поточном производстве мощностью более 1000кг/час.</p>


                                        <figure>
                                            <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\cookchill-cycle.jpg" 
                                            alt="Камера поточного охлаждения продуктов" />
                                            <figcaption style={{textAlign: "center"}}><em>Камера поточного охлаждения продуктов</em></figcaption>
                                        </figure>
                                        
                                    <p>Основная часть продукции, производимой по технологии Cook and Chill, не может иметь герметичной упаковки до начала её охлаждения. 
                                        Это связано с тем, данную технологию применяют в основном кейтеринговые компании и ресторанный бизнес, а их основной 
                                        ассортимент горячих блюд – мясо и гарниры. Несмотря на то, что герметичная упаковка горячего продукта позволяет исключить 
                                        обсеменение бактериями готовых блюд в камере шокового охлаждения и в последующей технологической цепочке, есть некоторые 
                                        важные факторы, которые не позволяют делать упаковку до начала охлаждения:</p>
                                        <ol>
                                            <li>Когда продукт очень горячий, из него интенсивно выходит влага – в особенности картофель, овощи, мясные и рыбные блюда. 
                                                Влага конденсируется на верхней части упаковки, а потом стекает вниз на сам продукт. В результате сильно ухудшается 
                                                вкус и внешний вид продукта, некоторые блюда «скисают» от избыточного конденсата.</li>
                                            <li>Для некоторых продуктов герметичная упаковка существенно удлиняет время охлаждения, что будет приводит к ухудшению 
                                                вкуса росту бактерий. Например, дозированные вторые блюда с рыхлым гарниром и кусочками мяса. Время охлаждения такого 
                                                продукта в открытом лотке или закрытой упаковке, которая не будет портить внешний вид, может отличаться очень 
                                                существенно (15 минут и 1,5часа). Пока продукт горячий – в нём продолжается ферментация белка и других питательных 
                                                веществ, по сути, он продолжает готовится.</li>
                                            <li>Сама упаковка и её маркировка. Далеко не всегда возможно применить для горячих блюд.</li>
                                        </ol>

                                    <p>Шоковое охлаждение в открытой упаковке потребует строгое выполнение гигиены: регулярная мойка камер и тележек, 
                                        периодическое обеззараживание бактерицидными лампами, стерильность до входа в камеру и после охлаждения.</p>

                                    <p>Некоторые блюда, не имеющие высокой температуры приготовления или составные блюда из ранее приготовленного, 
                                        такие как кондитерские изделия, мясные салаты и прочее могут упаковываться до охлаждения. При этом охлаждение в 
                                        упаковке будет более предпочтительным для дальнейшей безопасности продукта, т.к. внутри камеры охлаждения интенсивные 
                                        воздушные потоки и неизбежность обсеменения продукта. В данном случае важно учитывать, что более длительное время 
                                        охлаждения продукта в упаковке потребует большего размера камер охлаждения или спирального охладителя.</p>

                                    <p>Некоторый продукт, такой как вторые блюда с гарнирами, могут приготавливаться непосредственно в упаковке – это специальные 
                                        термостойкие лотки, которые могут загружаться на тележках в жарочные шкафы или при поточном производстве в автоматические 
                                        конвейерные печи. Продукт может частично обжариваться снаружи и быть предназначен для последующей доготовки перед употреблением.</p>

                                    <h2>В технологии Cook and Chill применяются следующие способы шокового охлаждения продукта:</h2>
                                    <h3>Охлаждение герметично упакованного продукта в ледяной воде.</h3>
                                    <p>Продукт после приготовления, не допуская падение температуры ниже +80°С, герметично упаковывают. 
                                        Далее загружают в специальные ящики, сетки или кассеты, которые в свою погружаются в ванные с циркулирующей ледяной водой, 
                                        имеющей температуру от +0,5 до +2°С. Охлаждение воды до столь низких температур осуществляется в плёночных охладителях 
                                        или специальных охладителях по принципу «речиллеров». Важно чтобы устройство данных охладителей на стороне охлаждаемой 
                                        воды было обслуживаемым и промываемым, т.к. есть риск повреждения упаковки и попадания продукта в оборотную ледяную воду. 
                                        Для интенсификации теплообмена применяют насосы рециркуляции воды внутри ванных охлаждения, а также барботаж сжатым воздухом. 
                                        Для явно выраженной цикличности (один цикл раз в сутки или раз в смену) для охлаждения ледяной воды применяются льдоаккумуляторы. 
                                        А для небольших производительностей можно применить стандартный льдогенератор и засыпать лёд в ванную охлаждения, 
                                        чтобы за счёт его плавления поддерживать температуру ледяной воды.</p>

                                        <figure>
                                            <img style={{width: '80%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\souse_cookchill.jpg" 
                                            alt="Охлаждение с помощью ледяной воды" />
                                            <figcaption style={{textAlign: "center"}}><em>Охлаждение с помощью ледяной воды.</em></figcaption>
                                        </figure>

                                    <h3>Цикличное охлаждение на тележках или на противнях в специальном шкафу охлаждения.</h3>
                                    <p>Данный способ имеет важное преимущество:</p>
                                        
                                        <ul class="post-ul-checked">
                                            <li class="post-li-checked">на современных тележечных камерах шокового охлаждения и шкафах возможен режим «Blast shocker», 
                                            когда сразу после загрузки температура снижается до -15..-25°С и горячий продукт не подмораживается, потом через 
                                            некоторое время температура внутри плавно увеличивается до -1..0°С. Такой способ интенсификации охлаждения позволяет 
                                            сократить полный цикл охлаждения, а также уменьшить выход влаги и быстрее остановить ферментацию белка 
                                            (продолжение приготовления) в период когда продукт очень горячий.</li>
                                            <p>Недостатки тоже есть:</p>
                                            <li class="post-li-checked">нельзя вносить горячий продукт в шокер, в котором не закончен цикл охлаждения ранее 
                                            загруженного продукта, следовательно, необходима организация технологического процесса, которая не будет допускать 
                                            простоя горячего продукта перед камерами шокового охлаждения.</li>
                                        </ul>

                                        <figure>
                                            <img 
                                            className="postcontent-image-sixty" 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\hornos-mixtos-industriales.jpg" 
                                            alt="Линейка современных шкафов охлаждения" />
                                            <figcaption style={{textAlign: "center"}}><em>Линейка современных шкафов охлаждения</em></figcaption>
                                        </figure>

                                    <h3>Охлаждение на тележках в потоке.</h3>
                                    <p>Здесь всё относительно просто. Тележки с горячим продуктом без ожидания сразу закатываются в камеру шокового охлаждения, 
                                        а после окончания охлаждения и достижения температуры внутри продукта +4..+6°С выкатываются на групповую упаковку и далее 
                                        в камеры хранения. Наиболее эффективное охлаждение достигается при скорости набегающего на продукт потока воздуха 3..4м/сек 
                                        и температуре -1..0°С (боле низкие температуры возможны, но не для всех продуктов, т.к. могут его подморозить и ухудшить 
                                        товарный вид). Необходимо обеспечить противоток воздушного потока движению продукта: наиболее холодный воздух на выходе 
                                        из воздухоохладителей должен попадать на продукт, который почти охлаждён и ближе к выходу; а наиболее нагретый воздух 
                                        должен обтекать наиболее горячий продукт, только что поступивший на охлаждение.</p>

                                        <figure>
                                            <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\IndustrialBlastFreezer.jpg" 
                                            alt="Цикличное охлаждение продуктов на тележках в камере" />
                                            <figcaption style={{textAlign: "center"}}><em>Цикличное охлаждение продуктов на тележках в камере</em></figcaption>
                                        </figure>

                                    <h3>Охлаждение в спиральном охладителе.</h3>
                                    <p>Это потоковая технология, которая применяется для производительности технологических линий более 1 тонны готового продукта в час. 
                                        Для крупной упаковки сегмента B2B (более 1кг) охлаждение в спиральном охладителе не применяется или применяется редко, 
                                        т.к. продукт имеющий большие размеры упаковки и большой вес охлаждается очень долго и потребуется спиральный охладитель 
                                        большого размера с широкой лентой. С маленькой упаковкой напротив можно получить стабильное качество в потоке и соединить 
                                        поточные технологические линии приготовления, упаковки и охлаждения.</p>

                                        <figure>
                                            <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\csm_proofline.jpg" 
                                            alt="Спиральный конвейер 'Heinen' для охлаждения или заморозки продуктов" />
                                            <figcaption style={{textAlign: "center"}}><em>Спиральный конвейер 'Heinen' позволяет добиться идеального качества охлаждения продуктов</em></figcaption>
                                        </figure>

                                    <p>После полного завершения цикла охлаждения время хранения определяется ТУ и может доходить до 3..6 месяцев для жидкого продукта. 
                                        Для дозированных вторых блюд такой длительный срок хранения - скорее редкое исключение, на практике в среднем это около двух 
                                        недель для блюд, которые герметично упаковываются в горячем виде до начала охлаждения или 3..5 дней для блюд, которые 
                                        упаковываются после охлаждения.</p>
                                    <p>Важно помнить, что срок хранения и безопасность продукта зависит не только от его охлаждения, а от всей цепочки технологии, 
                                        где на каждом этапе необходимо соблюдать строгие требования к гигиене и температурным режимам помещений.</p>

                                        <figure>
                                            <img style={{width: '100%', display: "grid", justifySelf: "center"}} 
                                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/contents\zamorozka\cook-n-chill\CookChill-Basket.jpg" 
                                            alt="Конвейер охлаждения по технологии Cook and Chill" />
                                            <figcaption style={{textAlign: "center"}}><em></em></figcaption>
                                        </figure>

                                    <p class="page-header">
                                        <strong>
                                            Компания «Рефинжиниринг» является официальным региональным партнером завода «Heinen Freezing» 
                                            ( <a href="https://www.heinen.biz/">www.heinen.biz</a> ) —
                                            ведущего европейского производителя конвейеров для шоковой заморозки и выполнит для Вас подбор оборудования, 
                                            его поставку, монтаж и обслуживание.
                                        </strong>
                                    </p>
                                
                        </div>
                </div>
            {/* PAGE CONTENT  */}

            <Footer />
        </>
    )
}

export default CookNChill;